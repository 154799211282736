import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import { onFind } from '@elements/init-modules-in-scope';

SwiperCore.use([Navigation, Pagination]);

const defaultSelectors = {};
const defaultOptions = {};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    options = {...defaultOptions, ...options};
    selectors = {...defaultSelectors, ...selectors};

    onFind('.js-gallery', function (baseElement) {
        const swiper = new Swiper('.js-gallery__container', {
            containerModifierClass:'js-gallery__container--',
            wrapperClass:'js-gallery__wrapper',
            slideClass:'js-gallery__slide',
            slidesPerView:1,
            spaceBetween:0,
            watchSlidesProgress:true,
            watchSlidesVisibility:true,
            slideVisibleClass:'is-visible',
            navigation:{
                prevEl:'.js-gallery__prev',
                nextEl:'.js-gallery__next',
                disabledClass:'is-disabled',
                hiddenClass:'is-hidden',
                lockClass:'is-locked'
            }
        });
    });
}