import { onFind } from '@elements/init-modules-in-scope';
import './scripts/common';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

import Collapse from 'bootstrap/js/dist/collapse';
onFind('[data-bs-toggle="collapse"]',function(element){
    new Collapse(element);
});

import * as lazyIframe from '@elements/lazy-iframe';
lazyIframe.init();

import * as lightbox from '@elements/lightbox';
lightbox.init();

import * as teaserSlider from './scripts/teaser-slider';
teaserSlider.init();

import * as video from './scripts/video';
video.init();

import * as gallery from './scripts/gallery';
gallery.init();